<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <v-card>
                <v-toolbar dense flat :color="primaryColor" dark>
                    <v-toolbar-title>Set up your account</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-progress-linear :color="primaryColor" height="8" :value="progress" striped class="mb-6">
                </v-progress-linear>
                <template v-if="step === 'check_status'">
                    <v-card-text>
                        <p>Preparing...</p>
                        <!-- <v-progress-circular :color="primaryColor" size="24" indeterminate class="mb-6"></v-progress-circular> -->
                    </v-card-text>
                </template>
                <template v-if="step === 'subscribe' && isViewReady">
                    <v-card-text>
                        <p>LibertyDNS partners with Unicorn Springs for secure payments and account management.</p>
                        <p>After this step you can set up your DNS!</p>
                        <a @click.prevent="subscribeWithUnicornSprings"><span style="border-bottom: 1px solid">Continue</span></a>
                    </v-card-text>
                </template>
                <template v-if="step === 'notice' && isViewReady">
                    <v-card-text>
                        <p>LibertyDNS is a new platform, but we expect all the features in your plan to be working as expected.</p>
                        <p>If you find that some feature is missing or broken, please use the question mark icon at the top of the page to get in touch with us. We actively prioritize the features and issues that customers like you are telling us are the most important.</p>
                        <p>The next step is to continue to your account dashboard from where you can manage your DNS records!</p>
                        <!-- <a @click.prevent="connectBrandProfile"><span style="border-bottom: 1px solid">Continue</span></a> -->
                        <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">Continue to dashboard</router-link>
                        <!-- <v-btn :color="primaryColor" @click="setup" class="white--text mt-4">Continue</v-btn> -->
                    </v-card-text>
                </template>
            </v-card>
            <p class="mb-15"></p>
            <v-alert type="error" v-if="serverError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="requestError">
                We could not send a verification email. Please try again. If the problem continues, try with a different email address or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="forbiddenError">
                The link is expired or invalid. Check that the email you entered is correct and try again.
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
// import { toText } from '@libertyio/time-util-js';
import { mapState, mapGetters } from 'vuex';
// import BrandList from '@/components/BrandList.vue';
import { UNICORNSPRINGS_INTENT_SIGNUP } from '@/sdk/constants';

export default {
    components: {
        // BrandList,
    },
    data: () => ({
        isViewReady: false,
        steps: ['check_status', 'subscribe', 'notice'],
        step: 'check_status',
        product: null,
        accountList: [],
        brandprofile: null,
        stripeAccountId: null,
        submitTimestamp: null,
        redirect: null,
        newAccountName: null,
        // older ones, check if we're using
        interactionId: null,
        verificationExpires: null,
        serverError: false,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        inputError: null,
        inputErrorTimeout: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
        registered: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
        // mainWebsiteURL() {
        //     return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://brandprofile.org';
        // },
        progress() {
            if (this.steps.length === 0) {
                return 0;
            }
            const idx = this.steps.findIndex((value) => value === this.step);
            return Math.ceil(((idx + 1) * 100) / this.steps.length);
        },
    },
    watch: {
        focus() {
            if (this.step === 'create_account') {
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('newAccountNameInput'); }, 1);
                });
            }
        },
        /*
        $route(newValue) {
            if (newValue.query.step !== this.step) {
                this.step = newValue.query.step;
                if (newValue.query.step === 'start') {
                    this.$nextTick(() => {
                        setTimeout(() => { this.activate('usernameInput'); }, 1);
                    });
                }
            }
        },
        */
    },
    methods: {
        resetErrors() {
            this.serverError = false;
            if (this.serverErrorTimeout) {
                clearTimeout(this.serverErrorTimeout);
                this.serverErrorTimeout = null;
            }
            this.requestError = false;
            if (this.requestErrorTimeout) {
                clearTimeout(this.requestErrorTimeout);
                this.requestErrorTimeout = null;
            }
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.forbiddenError = false;
            if (this.forbiddenErrorTimeout) {
                clearTimeout(this.forbiddenErrorTimeout);
                this.forbiddenErrorTimeout = null;
            }
            this.redirect = null;
            this.verificationExpires = null;
        },
        async check() {
            try {
                this.resetErrors();
                this.$store.commit('loading', { checkUserSetup: true });

                await this.loadAccountStatus();

                if (!this.account?.is_current && ['check_status', 'subscribe'].includes(this.$route.query.step ?? 'check_status')) {
                    this.step = 'subscribe';
                    this.isViewReady = true;
                    const query = { ...this.$route.query, step: 'subscribe', t: Date.now() };
                    this.$router.replace({ name: 'account-setup', query });
                    return;
                }

                this.step = 'notice';
                this.isViewReady = true;
                const query = { ...this.$route.query, step: 'notice', t: Date.now() };
                this.$router.replace({ name: 'account-setup', query });
            } catch (err) {
                console.error('failed to check user setup', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { checkUserSetup: false });
                this.isViewReady = true;
            }
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadAccountStatus() {
            // TODO: ask backend to conenct to stripe and check on account status... then reload account  so we have this.account.is_current  correct
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccountStatus: true });
                const response = await this.$client.account(this.$route.params.accountId).self.check({ item: 'billing' });
                console.log(`response ${JSON.stringify(response)}`);
                /*
{
    "status": {
        "account": "not-found",
        "user": "not-found"
    }
}
                */
                // await this.$store.dispatch('loadAccount', { accountId: this.$route.params.accountId }); // TODO: move this to where user comes BACK from unicorn springs with token, and we check the token, and the rn reload the accounta fter server reply
                if (response.status?.account === 'not-found' || response.status?.user === 'not-found') {
                    this.subscribeWithUnicornSprings();
                }
                // TODO: what do do if they're found?
            } catch (err) {
                console.error('failed to get link to Unicorn Springs', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to connect with Unicorn Springs' });
            } finally {
                this.$store.commit('loading', { loadAccountStatus: false });
            }
        },
        async subscribeWithUnicornSprings() {
            // TODO: do we have this.product or this.$route.query.product ? pass it on to unicorn springs to pre-select on plan selection page; e.g. 'essential', 'business', 'enterprise', 'dynamic-dns'
            try {
                this.error = false;
                this.$store.commit('loading', { subscribeWithUnicornSprings: true });
                const product = this.product ?? this.$route.query.product; // optional for unicorn springs
                const response = await this.$client.account(this.$route.params.accountId).unicornsprings.connect({ intent: UNICORNSPRINGS_INTENT_SIGNUP, product });
                // console.log(`response ${JSON.stringify(response)}`); // {"redirect":"https://...","redirect_max_seconds":300}
                if (response?.redirect) {
                    window.location.href = response.redirect;
                }
            } catch (err) {
                console.error('failed to get link to Unicorn Springs', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to connect with Unicorn Springs' });
            } finally {
                this.$store.commit('loading', { subscribeWithUnicornSprings: false });
            }
        },
        // // TODO: move this to store.js and call it with dispatch; so we don't load it in multiple activities redundantly, it can be reused.
        // async loadAccountList() {
        //     try {
        //         this.$store.commit('loading', { loadAccountList: true });
        //         const result = await this.$client.account.list();
        //         this.accountList = result.list;
        //     } catch (err) {
        //         console.error('failed to load account list', err);
        //         if (err.response?.status) {
        //             console.error(`response status: ${err.response.status}`);
        //             // TODO: 300 error codes? server shouldn't be redirecting us...
        //             if (err.response.status === 403) {
        //                 this.resetErrors();
        //                 this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
        //                 this.forbiddenError = true;
        //                 this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
        //             } else if (err.response.status >= 400 && err.response.status < 500) {
        //                 this.requestError = true;
        //                 this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
        //             } else if (err.response.status >= 500) {
        //                 this.serverError = true;
        //                 this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
        //             } else {
        //                 this.serverError = true;
        //                 this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
        //             }
        //         } else {
        //             this.serverError = true;
        //             this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
        //         }
        //     } finally {
        //         this.$store.commit('loading', { loadAccountList: false });
        //     }
        // },
        /*
        async loadSetting(name) {
            try {
                this.error = false;
                this.$store.commit('loading', { loadSetting: true });
                const response = await this.$client.account(this.$route.params.accountId).setting.get({ name });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                return response?.content ?? null;
            } catch (err) {
                console.error('failed to load setting', err);
                return null;
            } finally {
                this.$store.commit('loading', { loadSetting: false });
            }
        },
        async loadBrandProfile() {
            this.brandprofile = await this.loadSetting('brandprofile');
        },
        async loadStripeAccountId() {
            this.stripeAccountId = await this.loadSetting('stripe_account_id');
        },
        */
    },
    mounted() {
        this.check();
        /*
        // if user arrives with `email` and `step=verify_email`, call signup to check
        // the email address, if it's not verified we will redirect to EtherLink, and
        // eventually return here with a verified address or cancel the interaction
        if (this.$route.query.step === 'verify_email' && this.$route.query.email) {
            this.step = 'verify_email';
            this.username = this.$route.query.email;
            this.signup();
            return;
        }

        // if user arrives with `email` and `step=display_name`, show the email address and
        // ask user for their display name
        if (this.$route.query.step === 'display_name' && this.$route.query.email) {
            this.step = 'display_name';
            this.username = this.$route.query.email;
            this.isViewReady = true;
            this.$nextTick(() => {
                setTimeout(() => { this.activate('displayNameInput'); }, 1);
            });
            return;
        }

        // if user arrives with `email` and `step=setup_loginfront`, check that user is authenticated
        // and has the same email address and show that step, otherwise start over
        if (this.$route.query.step === 'setup_loginfront' && this.$route.query.email) {
            if (this.session.isAuthenticated && this.user.email === this.$route.query.email) {
                this.step = 'setup_loginfront';
                this.username = this.$route.query.email;
                this.isViewReady = true;
                return;
            }
        }

        // the first time user arrives on this page, there would not be an interaction id in the query,
        // but there could be an email address to pre-fill;
        // the second time the user arrives on this page (after email verification is completed), there
        // would be an interaction id also;
        // we only auto-submit if there's an interaction id meaning the user got here via one of our own links
        this.interactionId = this.$route.query.i;
        this.username = this.$route.query.email ?? '';
        if (this.interactionId && this.username) {
            this.signup();
            return;
        }

        this.isViewReady = true;
        this.$nextTick(() => {
            setTimeout(() => { this.activate('usernameInput'); }, 1);
        });
        // TODO: load brand info if we have this.$route.query.brand  (is probably etherlink brand for signup)
        // if (this.username) {
        //     this.search();
        // }
        */
    },
};
</script>
